<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import IconThumbsDown from "@/components/icons/phosphor/IconThumbsDown.svelte";
  import IconThumbsUp from "@/components/icons/phosphor/IconThumbsUp.svelte";
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'

  import { CorrectComment, CorrectUser, MyCommentVotes } from '@/definitions/langoid'

  export let my = false,
    myVote: MyCommentVotes = {} as MyCommentVotes,
    userId: number,
    user: CorrectUser = {} as CorrectUser,
    comment: CorrectComment,
    markVoteAsMy = (el: HTMLElement, action: string, vote: number) => {}

  let reportActive: HTMLFormElement | null,
    reportForm: HTMLFormElement
  const ELEMENT_DISPLACEMENT = 10

  const addCommentVoteUp = async (commentId: number, reason: string) => {
    const data = await fetchData('tasks/markComment', { commentId, reason })
    if (data.error) {
      notifier.warning($_(data.error))
    } else {
      const el: HTMLElement | null = document.getElementById(`comment-rating-${commentId}`)
      if (el) {
        el.innerText = data.count
        markVoteAsMy(el, data.action, data.rating)
        notifier.success($_('tasksPage.notifierVoteSaved'))
      }
    }
  }

  function showCommentReportWindow (commentId: number, vote: number, x: number, y: number): void {
    if (vote !== 1) {
      reportActive = reportForm
      reportActive.style.top = `${y + ELEMENT_DISPLACEMENT}px`
      reportActive.style.left = `${x + ELEMENT_DISPLACEMENT}px`
      reportActive.id = `comment_${commentId}`
    } else {
      reportActive = null
    }
  }

  const handleVoteOnComment = (event: MouseEvent, idComment: number, rating: number, idOfUser: number): void => {
    if (idOfUser === userId) {
      notifier.warning($_('tasksPage.commentOwnWarning'))
    } else {
      showCommentReportWindow(idComment, rating, event.pageX, event.pageY)
    }
  }

  const handleVoteReportSubmit = async (commentId: number) => {
    const length = reportForm.elements.length
    let reason = ''
    for (let i = 0; i < length; i++) {
      const input = reportForm.elements[i] as HTMLInputElement
      if (input.name === 'reason' && input.checked) {
        reason = input.id
        break
      }
    }

    const data = await fetchData('tasks/markComment', { commentId, reason })
    if (data.error) {
      return
    }
    const commentRatingElement: HTMLElement | null = document.getElementById(`comment-rating-${commentId}`)
    if (commentRatingElement) {
      commentRatingElement.innerText = data.count
      notifier.success($_('tasksPage.commentSuccess'))
    }

    reportActive = null
  }
</script>

<section id='comment-{comment.id}' class='comments-section'>
  <div class='single-comment'>
    <div class='left-side'>
      <div class='comment-header'>
        <UserAvatar date={comment.created_at} dateVariant='withDate' metaDataVariant='right' {user} withFlag />
      </div>
      <div class='comment-text'>
        {comment.comment}
      </div>
    </div>
    <div class='right-side'>
      <div class='vote-buttons -small' class:-disabled={my}>
        <div
          class='upvote'
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={() => !my && addCommentVoteUp(comment.id, 'just_like_it')}
        >
          <IconThumbsUp size='20' weight={myVote?.rating === 1 ? 'fill' : 'regular'} />
        </div>
        <div id='comment-rating-{comment.id}' class='voteRating'>{comment.votes || 0}</div>
        <div
          class='downvote'
          role='button'
          tabindex='0'
          on:keypress={() => {}}
          on:click={(event) => !my && handleVoteOnComment(event, comment.id, -1, comment.id_user)}
        >
          <IconThumbsDown size='20' weight={myVote?.rating === -1 ? 'fill' : 'regular'} />
        </div>
      </div>
    </div>
    <div class='downvote-report' class:active={reportForm === reportActive}>
      <form
        bind:this={reportForm}
        class='_form _vertical'
        action=''
        on:submit|preventDefault={() => handleVoteReportSubmit(comment.id)}
      >
        <CloseButton closeVariant='absolute' onClick={() => (reportActive = null)} on:keypress={() => {}} />
        <h3>{$_('report.selectReason')}:</h3>
        <p>
          <input id='rude' checked type='radio' name='reason' />
          <label for='rude'>{$_('report.rude')}</label></p>
        <p>
          <input id='spam' type='radio' name='reason' />
          <label for='spam'>{$_('report.spamExplained')}</label>
        </p>
        <p>
          <input id='reduntant' type='radio' name='reason' />
          <label for='reduntant'>{$_('report.redundantExplained')}</label>
        </p>
        <p>
          <input id='trash' type='radio' name='reason' />
          <label for='trash'>{$_('report.trashExplained')}</label></p>
        <p>
          <input id='useless' type='radio' name='reason' />
          <label for='useless'>{$_('report.uselessExplained')}</label></p>
        <input type='submit' value={$_('report.send')} />
      </form>
    </div>
  </div>
</section>

<style lang='scss'>
  .comments-section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;
  }

  .left-side {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 0.8rem 0;
  }

  .right-side {
    display: flex;
    align-items: center;
  }

  .downvote-report {
    position: absolute;
    z-index: 10;
    display: none;

    > form {
      gap: 2.4rem;
      width: 51.2rem;
      padding: 2.4rem;
      background: var(--Base-White);
      border-radius: 2.4rem;
      box-shadow: var(--medium-box-shadow);
    }
  }

  :global(.downvote-report.active) {
    display: block !important;
  }

  .single-comment {
    display: grid;
    grid-template-columns: 1fr 8rem;
    gap: 1.6rem;
    border-bottom: 0.1rem solid var(--Gray-Light);
  }

  .comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .comment-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font: var(--Regular-300);
  }

  @media (max-width: 768px) {
    .comments-section {
      clear: both;
      width: 90%;
    }

    .single-comment {
      margin-left: 0;
    }
  }
</style>
