<script lang='ts'>
  import { onMount } from 'svelte'

  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { getInterfaceLanguageId, getLearningLanguageId } from '@/helpers/apiCall'
  import { diff } from '@/helpers/diff'
  import { emojiAutocomplete } from '@/helpers/emojiHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { goToPseudoHash, toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import AudioRecorder from '@/components/audio/AudioRecorder.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import IconMedalMilitary from "@/components/icons/phosphor/IconMedalMilitary.svelte";
  import IconThumbsDown from "@/components/icons/phosphor/IconThumbsDown.svelte";
  import IconThumbsUp from "@/components/icons/phosphor/IconThumbsUp.svelte";
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Comment from '@/components/task/Comment.svelte'
  import CorrectionController from '@/components/task/CorrectionController.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import {
    CorrectApiData,
    CorrectComment,
    CorrectCorrections,
    CorrectMy,
    CorrectOriginal,
    CorrectUser,
    LanguagesList,
    MyCommentVotes, QuillType, QuillWrapper,
    TaskType,
    VoteData
  } from '@/definitions/langoid'

  let quill: QuillType

  export let iso: string,
    id: number,
    type: TaskType

  let taskRating: number
  let story: CorrectApiData
  let txt: string
  let audio = ''
  let comments: CorrectComment[] = []
  let correctionActive = false
  let corrections: CorrectCorrections[] = []
  let original: CorrectOriginal = {} as CorrectOriginal
  let my: CorrectMy[] = {} as CorrectMy[]
  let iCorrectedThisTask = false
  let myCommentVotes: Record<number, MyCommentVotes> = {}
  let users: Record<number, CorrectUser> = {}

  let userId: number
  const interfaceId = getInterfaceLanguageId()
  const languageId = getLearningLanguageId()
  let recordingState: boolean | string = false
  let everythingDisabled = false
  let content2 = { html: '', text: '' }
  const options2: any = {}
  let otherLangs: LanguagesList[] = []
  let showCommentInput = false
  let showCorrectioComment = false
  const breads = [
    { mobileUrl: `/${iso}/task/${type}/find`, text: '', url: `/${iso}` },
    { text: 'tasks.' + type, url: `/${iso}/task/${type}/find` },
    { text: $_('tasks.task') + ` ${id}` }
  ]

  onMount(() => {
    fetchData('tasks/correct', { id }).then((data: CorrectApiData) => {
      import('@/helpers/svelteQuill').then((el: QuillWrapper) => {
        quill = el.quill
      })
      story = data
      txt = story.plainText
      comments = story.comments
      original = data.original || {} as CorrectOriginal
      audio = data.audio_content
      userId = data.userId
      if (data.locked === 'yes') {
        everythingDisabled = true
      }
      my = data.my
      myCommentVotes = data.myCommentVotes
      iCorrectedThisTask = data.iCorrectedThisTask
      otherLangs = data.otherLangs || []
      users = data.users || {}
      for (const correctionsKey in story.corrections) {
        corrections = [...corrections, story.corrections[correctionsKey]]
      }
      corrections.sort((a, b) => {
        if (a.perfect === 'yes' && b.perfect !== 'yes') {
          return -1
        }
        if (a.perfect !== 'yes' && b.perfect === 'yes') {
          return 1
        }
        return b.rating - a.rating
      })

      const choosenLang = toInt(Object.keys(otherLangs).find(el => toInt(el) === story.id_language))
      if (choosenLang && otherLangs[choosenLang]) {
        original.audio_content = otherLangs[choosenLang].audio_content || ''
        original.richText = otherLangs[choosenLang].text_content
      }
      goToPseudoHash('correction')
      goToPseudoHash('comment')
    })
  })

  // Marking vote as my (task or correction
  const markVoteAsMy = (el: HTMLElement, action: string, vote: number): void => {
    if (!el || !el.parentNode) return
    const upvoteEl: HTMLElement | null = el.parentNode.querySelector('.upvote')
    const downvoteEl: HTMLElement | null = el.parentNode.querySelector('.downvote')
    if (upvoteEl) upvoteEl.classList.remove('my')
    if (downvoteEl) downvoteEl.classList.remove('my')
    if (action !== 'vote_removed') {
      if (downvoteEl && vote === -1) downvoteEl.classList.add('my')
      if (upvoteEl && vote === 1) upvoteEl.classList.add('my')
    }
  }

  const voteForStory = (e: MouseEvent | KeyboardEvent) => {
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return
    }

    const targetElement = e.target as HTMLElement
    const vote: number = targetElement.classList.contains('upvote') ? 1 : -1
    fetchData('tasks/vote', { correctionId: 0, taskId: id, vote }).then((data) => {
      if (data.error) {
        notifier.error($_('tasksPage.cantVoteOwnTask'))
      } else {
        const action = data.action
        taskRating = data.rating
        const element = document.getElementById('vote-' + id)
        if (element) {
          markVoteAsMy(element, action, vote)
          notifier.success($_(`tasksPage.notifier${action}`))
        }
      }
    })
  }

  const voteForCorrection = async (correctionId: number, vote: 1 | -1) => {
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return
    }
    const data: VoteData = await fetchData('tasks/vote', { correctionId, taskId: id, vote })
    const action = data.action
    if (data.error === 'tasksPage.cantVoteOwnCorrection') {
      notifier.warning($_('tasksPage.cantVoteOwnCorrection'))
    } else {
      notifier.success($_(`tasksPage.notifier${action}`))
      const el = document.getElementById('correction-' + correctionId)
      if (el) {
        el.innerText = String(data.rating)
        markVoteAsMy(el, action, vote)
      }
    }
  }

  const submitCorrection = async () => {
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return false
    }
    const textToSend = content2.html || txt
    if (textToSend === story.plainText) {
      notifier.error($_('tasksPage.storyNotCorrected'))
      return false
    }
    const data = await fetchData('tasks/addCorrection', { taskId: id, text: textToSend })
    correctionActive = false
    iCorrectedThisTask = true
    txt = story.richText
    notifier.success($_('tasksPage.correctionAdded'))
    corrections = [data.correction, ...corrections]
    users = { [data.user.id]: data.user, ...users }
    return true
  }

  const markAsPerfect = async (taskId: number, correctionId: number, event: MouseEvent | KeyboardEvent) => {
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return
    }
    const data = await fetchData('tasks/markAsPerfect', { correctionId, taskId })
    const targetElement = event.target as HTMLImageElement
    if (targetElement) {
      targetElement.src = data.action === 'added_perfect_vote' ? '/images/perfect-coloreda.svg' : '/images/perfect-black.svg'
    }
    if (!my[correctionId]) {
      my[correctionId] = {} as CorrectMy
    }
    my[correctionId].reason = data.action === 'added_perfect_vote' ? 'perfect' : ''
  }

  const submitComment = async (e: SubmitEvent) => {
    if (!e.target) return
    e.preventDefault()
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return
    }
    const targetElement = e.target as HTMLElement
    const childrenElement = targetElement.children[0] as HTMLTextAreaElement
    const comment = childrenElement.value

    const data = await fetchData('tasks/saveComment', { comment, correctionId: 0, taskId: id })
    childrenElement.value = ''
    comments = [...comments, data]
    users = { [data.id_user]: data, ...users }
    showCommentInput = false
    notifier.success($_('tasksPage.notifierCommentAddedSuccess'))
  }

  const submitCorrectionComment = async (e: SubmitEvent) => {
    if (everythingDisabled) {
      notifier.error($_('tasksPage.taskLocked'))
      return
    }

    const targetElement = e.target as HTMLElement
    const childElement = targetElement.children[0] as HTMLTextAreaElement
    const comment = childElement.value

    const [correctionId, index] = targetElement.id.split('-')[targetElement.id.split('-').length - 1].split('_').map(Number)
    const data = await fetchData('tasks/saveComment', { comment, correctionId, taskId: id })
    childElement.value = ''
    corrections[index].comments = [...corrections[index].comments, data]
    showCorrectioComment = false
    notifier.success($_('tasksPage.notifierCommentAddedSuccess'))
  }

  const onAfterSuccess = (data: any): boolean => {
    recordingState = false
    correctionActive = false
    iCorrectedThisTask = true
    txt = story.richText
    notifier.success($_('tasksPage.correctionAdded'))
    corrections = [data.correction, ...corrections]
    users = { [data.user.id]: data.user, ...users }
    return recordingState
  }

  const textChange = (e: CustomEvent) => {
    content2 = e.detail
  }
</script>
<Breadcrumb {breads}>
  <h2>{$_('tasksPage.title_' + type)}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='exercises' />
  </div>
</Breadcrumb>
<!-- TASK -->
{#if story}
  <div class='correct-page'>
    <div class='story-wrapper _gap24'>
      <!-- todo - add style for locked info -->
      {#if story.locked === 'yes'}
        <span class='locked-info'>{$_('tasksPage.locked')}</span>
      {/if}
      {#if type !== 'story' && Object.keys(original).length}
        <div class='correction-header'>
          <UserAvatar date={story.created_at} dateVariant='withDate' metaDataVariant='right' user={users[story.id_user]} />
          <div class='story-languages'>
            {#if Object.keys(otherLangs).length}
              {#each Object.keys(otherLangs) as langIndex}
                {@const lang = parseInt(langIndex)}
                <FakeButton
                  onClick={() => {
              original.audio_content = otherLangs[lang].audio_content || ''
              original.richText = otherLangs[lang].text_content
            }}
                >
                  <Flag id={lang} />
                </FakeButton>
              {/each}
            {/if}
          </div>
        </div>
        <hr />
        <div class='task-entry'>
          <div class='created-title'>
            {$_('tasksPage.createdFrom')}
          </div>
          <div>
            {#if original.audio_content}
              <AudioPlayer src={original.audio_content} variant='progress' />
            {:else}
              {@html original.richText}
            {/if}
          </div>
        </div>
        <hr />
      {/if}
      {#if audio}
        <div class='audio-play'>
          <AudioPlayer src={audio} variant='progress' />
        </div>
      {/if}
      <div class='story-content'>
        <ReportButton category='task' rel={id} />
        <div class='storyText'>{@html story.richText}</div>
        <div class='buttons-and-reactions'>
          {#if !everythingDisabled}
            <div class='correct-comment-buttons _row' class:-hide={showCommentInput || correctionActive}>
              {#if userId !== story.id_user && !iCorrectedThisTask}
                <button
                  class='correctButton'
                  class:-hide={correctionActive}
                  type='button'
                  on:click={() => {correctionActive = !correctionActive}}
                > {$_('tasksPage.addCorrection')} </button>
              {/if}
              <button type='button' on:click={() => {showCommentInput = true}}>{$_('tasksPage.addComment')}</button>
            </div>
          {/if}
          <div class='story-stats _row'>
            <div class='vote-buttons' class:-disabled={userId === story.id_user || everythingDisabled}>
              <div
                id='vote-up-{id}'
                class='upvote'
                role='button'
                tabindex='0'
                on:click={voteForStory}
                on:keypress={voteForStory}
              >
                <IconThumbsUp weight={my?.[0]?.rating === 1 ? 'fill' : 'regular'} />
              </div>
              <div id='vote-{id}' class='voteRating'>{taskRating ?? story.rating}</div>
              <div
                id='vote-down-{id}'
                class='downvote'
                role='button'
                tabindex='0'
                on:click={voteForStory}
                on:keypress={voteForStory}
              >
                <IconThumbsDown weight={my?.[0]?.rating === -1 ? 'fill' : 'regular'} />
              </div>
            </div>
            {#if story.perfect === 'yes'}
              <span class='perfectStory'><IconMedalMilitary size='28' weight='fill' /></span>
            {:else if my?.[0]?.reason && my[0].reason === 'perfect'}
              <FakeButton onClick={(e) => { markAsPerfect(id, 0, e) }} role='presentation' tabindex={-1}>
                <span class='perfectReason'><IconMedalMilitary size='28' weight='fill' /></span>
              </FakeButton>
            {:else}
              <FakeButton onClick={(e) => { markAsPerfect(id, 0, e) }} role='presentation' tabindex={-1}>
                <IconMedalMilitary size='28' weight='regular' />
              </FakeButton>
            {/if}
          </div>
        </div>
        <!-- Editing story -->
        <div class='edited-story' class:-active={correctionActive}>
          <!-- <h3 class="editHeading">{$_('tasksPage.correctionsTitle')}:</h3> -->
          {#if story.slug === 'read'}
            {#if id}
              <div class='save-or-cancel'>
                <AudioRecorder
                  changeState={(state) => (recordingState = state)}
                  {onAfterSuccess}
                  params={{ id, interfaceId, languageId, title: original.title }}
                  src={id.toString()}
                  url='/tasks/uploadCorrection'
                >
                  <span slot='start'>{$_('recording.start')}</span>
                  <span slot='stop'>{$_('recording.stop')}</span>
                </AudioRecorder>
                <button type='button' on:click={() => {correctionActive = false}}>{$_('mix.cancel')}</button>
              </div>
            {/if}
            <p class='editNote'>*{$_('tasksPage.readCorrectoinInstructions')}</p>
          {:else}
            {#if correctionActive}
              <Overlay onClick={() => {correctionActive = false}}>
                <div class='original-vs-correction'>
                  <div class='original-story'>
                    <h6>{$_('forum.originalPost')}</h6>
                    <p>{@html original.richText ? original.richText : $_('tasksPage.unavailableOriginal')}</p>
                  </div>
                  <hr class='_mobileOnly' />
                  <div class='edited-story' class:-active={correctionActive}>
                    <h6>{$_('mix.translation')}</h6>
                    {#if story.rel_type === 'normal'}
                      <textarea class='correctionTexArea' cols='30' rows='10' bind:value={txt} />
                    {:else}
                      {#if quill}
                        <div class='editor' on:text-change={textChange} use:quill={options2}>{@html story.richText}</div>
                      {/if}
                    {/if}
                    <p class='editNote'>{$_('tasksPage.dontCommentInCorrection')}</p>
                  </div>
                </div>
                <div class='save-or-cancel'>
                  <button class='send' type='button' value='Send' on:click={submitCorrection}>
                    {$_('mix.send')}</button>
                  {#if userId !== story.id_user && !everythingDisabled && !iCorrectedThisTask}
                    {#if recordingState !== 'stopped'}
                      <button
                        class='cancel'
                        type='button'
                        on:click={() => {
                              // workaround for audio recorder to stop
                              const tmpId = id
                              id = 0
                              setTimeout(() => {id = tmpId}, 1)
                              correctionActive = !correctionActive
                            }}
                      > {$_('mix.cancel')} </button>
                    {/if}
                  {/if}
                </div>
              </Overlay>
            {/if}
          {/if}
        </div>
        {#if !everythingDisabled && showCommentInput}
          <form id='add-comment-{id}' class='add-comment-form' action='' on:submit|preventDefault={submitComment}>
            <textarea
              placeholder={$_('tasksPage.addComment')}
              use:emojiAutocomplete
              use:resizeTextarea
            />
            <div class='save-or-cancel'>
              <button type='submit'>{$_('tasksPage.submitComment')}</button>
              <button type='button' on:click={() => {showCommentInput = false}}>{$_('mix.cancel')}</button>
            </div>
          </form>
        {/if}
      </div>
      <div class='task-comments'>
        {#if comments.length > 0}
          <hr />
        {/if}
        {#each comments as comment, index (comment.id_comment)}
          <Comment
            {comment}
            {markVoteAsMy}
            my={userId === comment.id_user}
            myVote={myCommentVotes?.[comment.id_comment]}
            user={users[comment.id_user]}
            {userId}
          />
        {/each}
      </div>
    </div>

    <!-- CORRECTIONS -->
    <section class='corrections-wrapper'>
      {#each corrections as correction, index(correction.id)}
        <div id='add-correction-{correction.id}' class='each-correction'>
          <div class='rectangle' />
          <div class='correction-content _gap24'>
            <div class='correction-header'>
              <UserAvatar date={correction.created_at} dateVariant='withDate' metaDataVariant='right' user={users[correction.id_user]} />
              <ReportButton category='correction' rel={id} />
            </div>
            <div class='audio-or-text-content'>
              {#if correction.correction_audio}
                <AudioPlayer src={correction.correction_audio} variant='progress' />
              {/if}
              {#if story.plainText && correction.plainText}
                <CorrectionController>
                  {@html diff(story.plainText, correction.plainText)
                    .replace(/\n+/g, '<br>')}
                </CorrectionController>
              {/if}
            </div>
            <div class='buttons-and-reactions'>
              <button
                class='addCorrectionCommentBtn'
                class:-hide={showCorrectioComment}
                type='button'
                on:click={() => {showCorrectioComment = true}}
              > {$_('tasksPage.addComment')} </button>
              <div class='story-stats'>
                <div class='vote-buttons' class:-disabled={userId === correction.id_user || everythingDisabled}>
                  <div
                    class='upvote'
                    role='button'
                    tabindex='0'
                    on:click={() => voteForCorrection(correction.id, 1)}
                    on:keypress={() => voteForCorrection(correction.id, 1)}
                  >
                    <IconThumbsUp weight={my?.[correction.id]?.rating === 1 ? 'fill' : 'regular'} />
                  </div>
                  <div id='correction-{correction.id}' class='voteRating'>{correction.rating}</div>
                  <div
                    class='downvote'
                    role='button'
                    tabindex='0'
                    on:click={() => voteForCorrection(correction.id, -1)}
                    on:keypress={() => voteForCorrection(correction.id, -1)}
                  >
                    <IconThumbsDown weight={my?.[correction.id]?.rating === -1 ? 'fill' : 'regular'} />
                  </div>
                </div>
                {#if correction.perfect === 'yes'}
                  <span class='perfectStory'><IconMedalMilitary size='28' weight='fill' /></span>
                {:else if my?.[correction.id]?.reason && my[correction.id].reason === 'perfect'}
                  <FakeButton
                    onClick={(e) => { markAsPerfect(id, correction.id, e) }}
                    onKeyPress={(e) => { if (e.key === 'Enter')markAsPerfect(id, correction.id, e) }}
                    role='presentation'
                    tabindex={-1}
                  >
                    <span class='perfectReason'><IconMedalMilitary size='28' weight='fill' /></span>
                  </FakeButton>
                {:else}
                  <FakeButton
                    onClick={(e) => { markAsPerfect(id, correction.id, e) }}
                    onKeyPress={(e) => { if (e.key === 'Enter')markAsPerfect(id, correction.id, e) }}
                    role='presentation'
                    tabindex={-1}
                  >
                    <IconMedalMilitary size='28' />
                  </FakeButton>
                {/if}
              </div>
            </div>
            {#if !everythingDisabled && showCorrectioComment}
              <form
                id='add-comment-{correction.id}_{index}'
                class='add-comment-form'
                action=''
                on:submit|preventDefault={submitCorrectionComment}
              >
                <textarea
                  placeholder={$_('tasksPage.addComment')}
                  use:emojiAutocomplete
                  use:resizeTextarea
                />
                <div class='save-or-cancel'>
                  <button type='submit'>{$_('tasksPage.submitComment')}</button>
                  <button type='button' on:click={() => {showCorrectioComment = false}}>{$_('mix.cancel')}</button>
                </div>
              </form>
            {/if}
            <div class='correction-comments'>
              {#if correction.comments.length > 0}
                <hr />
                {#each correction.comments as comment, index(comment.id)}
                  <Comment
                    {comment}
                    {markVoteAsMy}
                    my={userId === comment.id_user}
                    myVote={myCommentVotes?.[comment.id]}
                    user={users[comment.id_user]}
                    {userId}
                  />
                {/each}
              {/if}
            </div>
          </div>
        </div>
      {/each}
      {#if Object.keys(corrections).length === 0}
        <EmptyState title={$_('tasksPage.noCorrections')} />
      {/if}
    </section>
  </div>
{/if}
<style lang='scss'>
  .corrections-wrapper {
    margin-left: 2.4rem;
  }

  .correction-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .story-languages {
    display: flex;
    gap: 0.8rem;
  }

  .audio-or-text-content {
    padding: 0.8rem 1.6rem;
    background-color: var(--success-background);
    border-right: 0.15rem solid var(--success-text);
    border-left: 0.15rem solid var(--success-text);
  }

  .task-entry {
    background: var(--Primary-Lighter);
  }

  .each-correction {
    display: flex;
    align-items: stretch;
    border-left: 0.1rem solid var(--Gray-Medium);

    > .rectangle {
      min-width: 2.4rem;
      height: 4rem;
      border-bottom: 0.1rem solid var(--Gray-Medium);
      border-radius: 0 0 0 1.6rem;
    }

    &:nth-last-child(1) {
      border-left: none;

      > .rectangle {
        border-left: 0.1rem solid var(--Gray-Medium);
      }
    }
  }

  .story-content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > :global(.fake-button) {
      align-self: end;
    }

    > .storyText {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      line-height: 1.5;
    }
  }

  .correction-content {
    width: 100%;
    margin-top: 1.6rem;
    padding: 0;
    background-color: inherit;
    border: none;
  }

  .correction-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .story-stats {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    > .perfectStory {
      height: 2.8rem;
      color: var(--Accent-Medium);
    }

    > :global(.fake-button .perfectReason) {
      height: 3.4rem;
      color: var(--Success-Light);
    }
  }

  .created-title {
    font-size: 0.8em;
  }

  .buttons-and-reactions {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    justify-content: space-between;

    > .addCorrectionCommentBtn {
      &.-hide {
        visibility: hidden;
      }
    }
  }

  .correct-comment-buttons {
    &.-hide {
      visibility: hidden;
    }

    > .correctButton {
      &.-hide {
        display: none;
      }
    }
  }

  .original-vs-correction {
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: 1fr 1fr;
  }

  .original-story {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    overflow-y: auto;
    max-height: 70vh;
  }

  .edited-story {
    display: none;

    &.-active {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      > .editNote {
        margin: 0;
        font-size: 1.2rem;
        color: var(--text-primary-color);
      }

      > .correctionTexArea {
        width: 100%;
        height: 100%;
        min-height: calc(100% - 10rem);
        border: 0.1rem solid var(--Error-Lighter);
        border-radius: 0.2rem;
        box-shadow: var(--medium-box-shadow);
      }
    }

    > .editor {
      overflow: auto;
      max-height: 60vh;
    }
  }

  .save-or-cancel {
    display: flex;
    gap: 0.8rem;
    align-self: end;
  }

  .add-comment-form {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    width: 100%;

    > :global(textarea) {
      width: 100%;
      height: 4.8rem;
    }
  }

  @media (max-width: 768px) {
    .original-vs-correction {
      display: grid;
      grid-template-rows: 30vh 1fr 3rem;
      overflow: auto;
    }

    .story-wrapper {
      padding: 0;
      background-color: inherit;
      border: none;
    }

    .corrections-wrapper {
      margin-left: 0;
    }

    .task-entry {
      border: 0;
      border-radius: 0;
    }

    .story-stats {
      > .perfectStory {
        width: 2.4rem;
      }
    }

    .buttons-and-reactions {
      flex-direction: column-reverse;
    }

    .correct-comment-buttons {
      align-self: start;
    }

    .story-stats {
      align-self: end;
    }

    .add-comment-form {
      > :global(textarea) {
        width: 100%;
      }
    }
  }
</style>
