<script lang='ts'>

  import { _ } from '@/helpers/i18n'

  import IconInfo from "@/components/icons/phosphor/IconInfo.svelte";
  import Kebab from '@/components/ui/Kebab.svelte'
  import KebabItem from '@/components/ui/KebabItem.svelte'

  let correctionView = 'normal'

</script>

<div
  class='correction-wrapper'
  class:-corrected={correctionView === 'corrected'}
  class:-normal={correctionView === 'normal'}
  class:-original={correctionView === 'original'}
>
  <div class='correction-top'>
    <span class='infoTitle'> <IconInfo size='16' weight='fill' />{$_('chat.suggestedCorrection')}</span>
    <Kebab>
      <KebabItem
        icon='Check'
        label={$_('mix.correctionType_normal')}
        select={() => {correctionView = 'normal'}}
        selected={correctionView === 'normal'}
        size='16'
      />
      <hr />
      <KebabItem
        icon='Check'
        label={$_('mix.correctionType_corrected')}
        select={() => {correctionView = 'corrected'}}
        selected={correctionView === 'corrected'}
        size='16'
      />
      <hr />
      <KebabItem
        icon='Check'
        label={$_('mix.original')}
        select={() => {correctionView = 'original'}}
        selected={correctionView === 'original'}
        size='16'
      />
    </Kebab>
  </div>
  <div class='correctionText'>
    <slot />
  </div>
</div>

<style lang='scss'>
  .correction-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &.-corrected {
      > .correctionText {
        > :global(del) {
          display: none;
        }
      }
    }

    &.-normal {
      > .correctionText {
        > :global(del) {
          position: relative;
          top: 0.4rem;
          padding: 0 0.4rem;
          font-size: 1.2rem;
          opacity: 0.8;
        }
      }
    }

    &.-original {
      > .correctionText {
        > :global(del) {
          position: static;
          font-size: 1em;
          line-height: 1.5;
          color: var(--Gray-Darker);
          background-color: var(--Error-Light);
        }
      }
    }

    &.-original {
      > .correctionText {
        > :global(ins) {
          display: none;
        }
      }
    }
  }

  .correction-top {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;

    > .infoTitle {
      display: flex;
      align-items: center;
      float: left;
      font: var(--Medium-200);
      color: var(--success-text);
    }
  }

  @media(max-width: 768px) {
    .correction-top {
      flex-wrap: wrap;
    }
  }
</style>
